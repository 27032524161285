import Vue from 'vue';
import CostlistService from '@/app/costlist/costlist.service';

const getDefaultMaw = () => ({
    intMaw: 120,
    extMaw: 150,
    travelFlatRate: 100,
});

const getDefaultCapacities = () => ({
    test: 0,
});

const getDefaultItem = () => ({
    _id: null,
    maw: getDefaultMaw(),
    capacities: getDefaultCapacities(),
});

const getDefaultState = () => ({
    item: getDefaultItem(),
});

const costlist = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await CostlistService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { data } = await CostlistService.update(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context) {
            const { data } = await CostlistService.findFirst();
            context.commit('update', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
    },
};
export { getDefaultItem };
export default costlist;
