import Vue from 'vue';
import TenantService from './tenant.service';
import * as _ from 'lodash';
import ApiService from '@/common/api.service';

const getDefaultAddress = () => ({
    _id: null,
    street: null,
    housenumber: null,
    zip: null,
    location: null,
    tel: null,
    email: null,
    homepage: null,
    country: null,
});

const getDefaultItem = () => ({
    _id: null,
    book: [],
    name: '',
    url: '',
    fusszeileText: '',
    addressName: '',
    notificationEmail: '',
    user: [],
    hotHint: [],
    defaultTechAcceptor: null,
    techAcceptor: false,
    importDataClarificationSheet: false,
    address: getDefaultAddress(),
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
    template: [],
    techAcceptor: [],
});

const tenant = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            if (data.book) {
                for (let i = 0; i < data.book.length; i++) {
                    let aBook = data.book[i];
                    if (Array.isArray(aBook.documents) && aBook.documents.length > 0) {
                        aBook.src = ApiService.apiUrl() + 'blobs/' + aBook.documents[0] + '/document';
                    }
                }
            }

            Vue.set(state, 'item', data);
        },
        updateTemplate(state, data) {
            Vue.set(state, 'template', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateTechAcceptor(state, data) {
            Vue.set(state, 'techAcceptor', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'book', JSON.parse(JSON.stringify(getDefaultState().item.book)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async techAcceptor(context) {
            const { data } = await TenantService.getTechAcceptor();
            context.commit('updateTechAcceptor', data);
            return data;
        },
        async template(context) {
            const { data } = await TenantService.template();
            context.commit('updateTemplate', data);
            return data;
        },
        async create(context, client_data) {
            delete client_data._id;
            delete client_data.__v;
            delete client_data.book;
            delete client_data.user;
            const { data } = await TenantService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const id = client_data._id;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.book;
            delete client_data.user;
            const { data } = await TenantService.update(id, client_data);
            context.commit('update', data);
            return data;
        },
        async updateAdmin(context, client_data) {
            const id = client_data._id;
            delete client_data._id;
            delete client_data.__v;
            delete client_data.book;
            const { data } = await TenantService.update(id, client_data, false);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await TenantService.get(id);
            if (!data.address) {
                data.address = {};
            }

            if (!data.techAcceptor) {
                data.techAcceptor = false;
            }
            context.commit('update', data);
            return data;
        },
        async getByUrl(context, id) {
            const { data } = await TenantService.getByUrl(id);
            context.commit('update', data);
            return data;
        },
        async getByUrlAndPath(context, client_data) {
            const { url, folder, currentPage, sort, size } = client_data;
            const { data } = await TenantService.getByUrlAndPath(url, folder, currentPage, sort, size);
            context.commit('update', data);

            let currPage = client_data && client_data.currentPage ? client_data.currentPage : 0;
            context.commit('updateCurrentPage', currPage);
            context.commit('updateList', data.book);
            context.commit('updateTotalPages', data.totalPages);
            return data;
        },
        async getByUserId(context, id) {
            const { data } = await TenantService.getByUserId(id);
            return data;
        },
        async query(context, params) {
            const { data } = await TenantService.query(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await TenantService.delete(slug);
            context.commit('updateBook', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        getTemplate(state) {
            return state.template;
        },
        getTechAcceptor(state) {
            return state.techAcceptor;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default tenant;
