import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'customerlocation';

const CustomerlocationService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async getAmpelCount(params) {
        return ApiService.getCustom(resource + '/getampelcount/' + params.params.searchplzorort + '/' + params.params.searchznlornrorsapnr);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async removeBook(clId, bookID) {
        let promise = null;
        try {
            promise = await ApiService.get(`${resource}/removebook/${clId}/${bookID}`);
            Toast.showSaveOkToastText('Erfolgreich entfernt');
        } catch (e) {
            Toast.showSaveErrorToastText('Fehler beim Entfernen');
        }
        return promise;
    },
    async checkInspectionAmpelByBookId(bookId) {
        return await ApiService.get(`${resource}/checkinspectionampelbybookid/${bookId}`);
    },
};

export default CustomerlocationService;
