import ApiService from '@/common/api.service';

const resource = 'auditlogs';

const AuditlogService = {
    async queryByBook(params) {
        return ApiService.get(resource + '/book', params);
    },
};

export default AuditlogService;
