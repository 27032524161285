<template>
    <div class="container">
        <div v-if="!editmode" class="row">
            <div class="col-12">
                <span
                    >Adresse: {{ address.street ? address.street : '?' }} {{ address.housenumber ? address.housenumber : '?' }}
                    {{ address.zip ? address.zip : '?' }} {{ address.location ? address.location : '?' }} ...</span
                >
                <button type="button" class="btn btn-link" @click="editmode = true">
                    <font-awesome-icon :icon="['fad', 'pencil']" />&nbsp;<span>Details Bearbeiten</span>
                </button>
            </div>
        </div>

        <div v-if="editmode">
            <div class="row form-group">
                <div class="col-md-8">
                    <label class="control-label" for="field_street">{{ $t('street') }}</label>
                    <input
                        id="field_street"
                        type="text"
                        class="form-control"
                        name="street"
                        :value="address.street"
                        @input="setProp('street', $event.target.value)"
                    />
                    <!--<validation :validation="$v.address.street"></validation>-->
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="field_housenumber">{{ $t('housenumber') }}</label>
                    <input
                        id="field_housenumber"
                        type="text"
                        class="form-control"
                        name="housennumber"
                        :value="address.housenumber"
                        @input="setProp('housenumber', $event.target.value)"
                    />
                    <!--<validation :validation="$v.address.housenumber"></validation>-->
                </div>
            </div>
            <div class="row form-group">
                <div class="col-md-4">
                    <label class="control-label" for="field_zip">{{ $t('zip') }}</label>
                    <input id="field_zip" type="text" class="form-control" name="zip" :value="address.zip" @input="setProp('zip', $event.target.value)" />
                    <!--<validation :validation="$v.address.zip"></validation>-->
                </div>
                <div class="col-md-8">
                    <label class="control-label" for="field_location">{{ $t('location') }}</label>
                    <input
                        id="field_location"
                        type="text"
                        class="form-control"
                        name="location"
                        :value="address.location"
                        @input="setProp('location', $event.target.value)"
                    />
                    <!--<validation :validation="$v.address.location"></validation>-->
                </div>
            </div>
            <div class="form-group">
                <label class="control-label" for="field_country">{{ $t('country') }}</label>
                <select id="field_country" v-model="address.country" class="form-control">
                    <option />
                    <option v-for="country in countries" :key="country.code" :value="country._id">
                        {{ country.name }}
                    </option>
                </select>
                <!--<validation :validation="$v.address.country"></validation>-->
            </div>
            <div class="row form-group">
                <div class="col-md-4">
                    <label class="control-label" for="field_tel">{{ $t('tel') }}</label>
                    <input id="field_tel" type="text" class="form-control" name="tel" :value="address.tel" @input="setProp('tel', $event.target.value)" />
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="field_email">{{ $t('email') }}</label>
                    <input id="field_email" type="text" class="form-control" name="tel" :value="address.email" @input="setProp('email', $event.target.value)" />
                </div>
                <div class="col-md-4">
                    <label class="control-label" for="field_tel">{{ $t('homepage') }}</label>
                    <input
                        id="field_homepage"
                        type="text"
                        class="form-control"
                        name="homepage"
                        :value="address.homepage"
                        @input="setProp('homepage', $event.target.value)"
                    />
                </div>
                <div class="col-md-12">
                    <button type="button" class="btn btn-link" @click="editmode = false">
                        <font-awesome-icon :icon="['fad', 'check']" />&nbsp;<span>Details OK</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'AddressComp',
    // eslint-disable-next-line vue/require-prop-types
    props: ['address'],
    data() {
        return {
            editmode: false,
        };
    },
    computed: {
        ...mapGetters({
            countries: 'country/list',
        }),
    },
    mounted() {
        if (this.countries.length === 0) {
            this.loadData();
        }
    },
    methods: {
        setProp(name, value) {
            this.address[name] = value;
            this.$emit('address', { value: value, id: name });
        },
        loadData() {
            this.$store.dispatch('country/query').then((countries) => {
                if (this.address.country === null) {
                    this.$set(this.address, 'country', countries[0]._id);
                }
            });
        },
    },
    validations: {
        address: {
            street: {},
            housenumber: {},
            location: {},
            zip: {},
            country: {},
        },
    },
};
</script>

<style scoped></style>

<!--language=json -->
<i18n>
    {
        "de": {
            "street": "Straße",
            "housenumber": "Hausnummer",
            "zip": "PLZ",
            "location": "Ort",
            "country": "Land",
            "tel": "Telefon",
            "email": "Email",
            "homepage": "Homepage"
        },
        "en": {
            "street": "Street",
            "housenumber": "HouseNo",
            "zip": "ZIP",
            "location": "Location",
            "country": "Country",
            "tel": "Phone",
            "email": "Email",
            "homepage": "Homepage"
        }
    }
</i18n>
