import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'users';

const TotpService = {
    async get2FaBarcode(slug) {
        let promise = null;
        try {
            promise = await ApiService.get(resource + '/barcode2fa/' + slug);
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async validateCode(client_data) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + '/finish2fa', client_data);
            Toast.showSaveOkToastI18n('global.menu.account.success2factorauth');
        } catch (e) {
            Toast.showSaveErrorToastI18n('global.menu.account.error2factorauth');
        }
        return promise;
    },
    async disable2Fa(client_data) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + '/deactivate2fa', client_data);
            Toast.showSaveOkToastI18n('global.menu.account.success2factorauthdeactivate');
        } catch (e) {
            Toast.showSaveErrorToastI18n('global.menu.account.error2factorauth');
        }
        return promise;
    },
    async send2FaDeactivationEmail(client_data) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + '/send2FaDeactivationEmail', client_data);
            Toast.showSaveOkToastI18n('global.menu.account.success2factoremail');
        } catch (e) {
            Toast.showSaveErrorToastI18n('global.menu.account.error2factoremail');
        }
        return promise;
    },
    async deactivate2Fa(key) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + '/deactivate2fa/' + key);
        } catch (e) {
            Toast.showSaveErrorToastI18n('global.menu.account.error2factordisable');
            return e;
        }
        return promise;
    },
};

export default TotpService;
