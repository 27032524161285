import Vue from 'vue';
import CountryService from './country.service';

const getDefaultItem = () => ({
    id: null,
    name: null,
    code: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
});

const user = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async query(context) {
            const { data } = await CountryService.query();
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        list(state) {
            return state.list;
        },
    },
};

export default user;
