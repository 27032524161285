import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'users';

const UseradministrationService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getRoles() {
        return ApiService.get(resource + '/roles/all');
    },
    async getCustomerRoles() {
        return ApiService.get(resource + '/customerroles/all');
    },
    async getCustomers() {
        return ApiService.get(resource + '/customers/all');
    },
    async findOneIdByAbbreviation(search) {
        return ApiService.get(resource + '/findOneIdByAbbreviation', search);
    },
    async getDepartments() {
        return ApiService.get(resource + '/departments/all');
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.put(resource + '/admin/' + slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default UseradministrationService;
