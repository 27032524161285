import Vue from 'vue';
import AdminService from './admin.service';

const getDefaultItem = () => ({
    id: null,
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    allDailyParticipants: [],
    currentPage: 1,
    totalPages: 1,
});

const admin = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateAllDailyInspections(state, data) {
            Vue.set(state, 'allDailyInspections', data);
        },
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'allDailyInspections', JSON.parse(JSON.stringify(getDefaultState().allDailyInspections)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async getAllDailyInspections(context, dateYYYYMMDD) {
            const { data } = await AdminService.getAllDailyInspections(dateYYYYMMDD);
            context.commit('updateAllDailyInspections', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        allDailyInspections(state) {
            return state.allDailyInspections;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default admin;
