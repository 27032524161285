const config = {
    'pb.hilarion5.software': {
        backendBaseUrl: 'https://pb.hilarion5.software/api/v1/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'prod'
    },
    'pbdev.hilarion5.software': {
        backendBaseUrl: 'https://pbdev.hilarion5.software/api/v1/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'acc'
    },
    'dev.xn--prfbcher-75ac.at': {
        backendBaseUrl: 'https://dev.xn--prfbcher-75ac.at/api/v1/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'acc'
    },
    localhost: {
        backendBaseUrl: 'http://localhost:3000/api/v1/',
        I18N_LOCALE: 'de',
        I18N_FALLBACK_LOCALE: 'en',
        stage: 'local'
    },
};
export default config;
