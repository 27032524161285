import Vue from 'vue';
import * as _ from 'lodash';
import OpevaluationService from '@/app/opevaluation/opevaluation.service';

const getDefaultItem = () => ({
    monat: null,
    betrag: null,
    mawBetrag: null,
    anzahl: null,
    anzahlMaw: null,
});

const getDefaultState = () => ({
    // item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
});

const opevaluation = {
    namespaced: true,
    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async getOpevaluation(context, params) {
            const { data } = await OpevaluationService.getOpevaluation(params);
            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);
            let totalPages = data.pages;
            context.commit('updateTotalPages', _.ceil(totalPages));
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};
export { getDefaultItem };
export default opevaluation;
