import ApiService from '@/common/api.service';

const resource = 'orders';

const OpevaluationService = {
    async getOpevaluation(params) {
        return ApiService.query(resource + '/getOpevaluation/all', params);
    },
};

export default OpevaluationService;
