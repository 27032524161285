import Vue from 'vue';
import BooktypeService from './booktype.service';
import * as _ from 'lodash';

const getDefaultItem = () => ({
    type: '',
    component: '',
    icon: '',
    color: '',
    serviceinterval: 0,
    selfserviceinterval: 0,
});

const getDefaultServiceInterval = () => ({});

const getDefaultState = () => ({
    item: getDefaultItem(),
    list: [],
    currentPage: 1,
    totalPages: 1,
    serviceinterval: getDefaultServiceInterval(),
});

const booktype = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', Object.assign({}, getDefaultState().item, data));
        },
        updateServiceInterval(state, data) {
            Vue.set(state, 'serviceinterval', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async create(context, client_data) {
            const { data } = await BooktypeService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async update(context, client_data) {
            const { _id } = client_data;
            delete client_data._id;
            delete client_data.__v;
            const { data } = await BooktypeService.update(_id, client_data);
            context.commit('update', data);
            return data;
        },
        async get(context, id) {
            const { data } = await BooktypeService.get(id);
            context.commit('update', data);
            return data;
        },
        async query(context, params) {
            const { data } = await BooktypeService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async queryActive(context, params) {
            const { data } = await BooktypeService.queryActive(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await BooktypeService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
        async getServiceInterval(context) {
            const { data } = await BooktypeService.getServiceInterval();
            context.commit('updateServiceInterval', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        serviceinterval(state) {
            return state.serviceinterval;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default booktype;
