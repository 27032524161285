import ApiService from '@/common/api.service';

const resource = 'pdfassets';

const PdfassetService = {
    async queryByBook(params) {
        return ApiService.get(resource + '/book', params);
    },
};

export default PdfassetService;
