<template>
    <div>
        <navbar></navbar>

        <div>
            <!--      <sidebar></sidebar>-->
            <div v-if="updateExists">
                <transition name="modal">
                    <div class="modal-mask">
                        <div class="modal-wrapper">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title">Update verfügbar</h5>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" @click="updateExists = false">Später ausführen</button>
                                        <button type="button" class="btn btn-primary" @click="refreshApp">Aktualisierung starten</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
            </div>
            <section id="main-content">
                <div class="container-fluid">
                    <div style="height: 20px"></div>
                    <div class="main-content" style="padding-left: 0; padding-right: 0">
                        <router-view></router-view>
                    </div>

                    <div style="height: 20px"></div>
                </div>
            </section>
        </div>
        <footer class="footer">
            <div class="container" style="text-align: center; line-height: 2">
                <span class="muted mobile-footer"
                    >hilarion 5 IT Solutions &nbsp;&copy; 2016 - {{ year }}
                    <a href="https://hilarion5.com" target="_blank">hilarion 5</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a
                        href="https://hilarion5.com/datenschutzerklaerung/"
                        target="_blank"
                        >Datenschutz</a
                    >&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://hilarion5.com/contact/" target="_blank">Impressum</a>
                </span>
                <span class="muted mobile-footer-show"
                    >hilarion 5 IT Solutions &nbsp;&copy; 2016 - {{ year }} <br />
                    <a href="https://hilarion5.com" target="_blank">hilarion 5</a><br /><a href="https://hilarion5.com/datenschutzerklaerung/" target="_blank"
                        >Datenschutz</a
                    >&nbsp;&nbsp;|&nbsp;&nbsp;<a href="https://hilarion5.com/contact/" target="_blank">Impressum</a>
                </span>
            </div>
        </footer>
    </div>
</template>

<script>
import navbar from './app/navigation/navbar';

export default {
    name: 'App',
    components: {
        navbar,
    },
    data() {
        return {
            year: new Date().getFullYear(),
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },
    mounted: function () {},
    created() {
        document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
        document.addEventListener('wheel', function () {
            if (document.activeElement.type === 'number') {
                document.activeElement.blur();
            }
        });
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) return;
                this.refreshing = true;
                window.location.reload();
            });
        }
    },
    methods: {
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },
        refreshApp() {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage('skipWaiting');
        },
    },
};
</script>

<style>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    padding-top: 0px !important;
}
</style>

<i18n>
  {
  "de": {
  },
  "en": {
  }
  }
</i18n>
