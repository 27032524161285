import Vue from 'vue';
import PdfassetService from './pdfassets.service';

const getDefaultState = () => ({
    list: [],
});

const pdfassets = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        clear(state) {
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
        },
    },

    actions: {
        clear({ commit }) {
            commit('clear');
        },
        async queryByBook(context, params) {
            const { data } = await PdfassetService.queryByBook(params);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        list(state) {
            return state.list;
        },
    },
};

export default pdfassets;
