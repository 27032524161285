import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'costlist';

const CostlistService = {
    async get() {
        return ApiService.get(resource);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async findFirst() {
        let promise = null;
        try {
            promise = await ApiService.get(resource + '/find/first');
        } catch (e) {
            Toast.showSaveErrorToastText(e);
        }
        return promise;
    },
};

export default CostlistService;
