import ApiService from '@/common/api.service';

const resource = 'country';

const CountryService = {
    async query() {
        return ApiService.query(resource);
    },
};

export default CountryService;
