<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light">
        <router-link :to="{ name: 'home' }" class="navbar-brand">
            <img src="~/@/assets/logo_dap.png" height="40" class="d-inline-block align-top" alt="" />
        </router-link>

        <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            <span class="navbar-toggler-icon"></span>
        </button>

        <div id="navbarSupportedContent" class="collapse navbar-collapse">
            <ul class="navbar-nav w-100">
                <li class="nav-item" v-if="!hasRoleProjectOnly && !hasRoleFiliale">
                    <router-link v-if="isAuthenticated" :to="{ name: 'tenant_list' }" class="nav-link">
                        <font-awesome-icon :icon="['fad', 'map-marker-smile']" />&nbsp;
                        <span>{{ $t('near') }}</span>
                    </router-link>
                </li>
                <li v-if="!hasRoleProjectOnly && !hasRoleFiliale">
                    <router-link v-if="isAuthenticated" :to="{ name: 'list_allmyinspections' }" class="nav-link">
                        <font-awesome-icon :icon="['fad', 'calendar-check']" />&nbsp;
                        <span>Meine Überprüfungen</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        v-if="isAuthenticated && (hasRoleAdmin || hasRoleProject || hasRoleProjectOnly || hasRoleLager || hasRoleServiceTechniker)"
                        :to="{ name: 'list_orderprocessing' }"
                        class="nav-link"
                    >
                        <font-awesome-icon :icon="['fad', 'file-edit']" />&nbsp;
                        <span>Auftragsbearbeitung</span>
                    </router-link>
                </li>
                <li>
                    <router-link v-if="isAuthenticated && (hasRoleAdmin || hasRoleAuswertung)" :to="{ name: 'opevaluation' }" class="nav-link">
                        <font-awesome-icon :icon="['fad', 'money-check-edit-alt']" />&nbsp;
                        <span>Auswertung</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        v-if="
                            isAuthenticated &&
                            (hasRoleAdmin ||
                                hasRoleProject ||
                                hasRoleProjectOnly ||
                                hasRoleServiceTechniker ||
                                (hasRoleFiliale && (hasCustomerRoleRegionlManager || hasCustomerRoleControl)))
                        "
                        :to="{ name: 'customerlocation_list' }"
                        class="nav-link"
                    >
                        <font-awesome-icon :icon="['fad', 'building']" />&nbsp;
                        <span>Filialen</span>
                    </router-link>
                </li>
                <li>
                    <router-link
                        v-if="
                            isAuthenticated &&
                            (hasRoleAdmin ||
                                hasRoleServiceTechniker ||
                                (hasRoleFiliale && (hasCustomerRoleRegionlManager || hasCustomerRoleControl)))
                        "
                        :to="{ name: 'qrcode_scan' }"
                        class="nav-link"
                    >
                        <font-awesome-icon :icon="['fad', 'qrcode']" />&nbsp;
                        <span>QRCode</span>
                    </router-link>
                </li>
                <li>
                    <router-link v-if="isAuthenticated && hasRoleAdmin" :to="{ name: 'costlist' }" class="nav-link">
                        <font-awesome-icon :icon="['fad', 'money-check']" />&nbsp;
                        <span>Kostenliste</span>
                    </router-link>
                </li>
                <li class="nav-item dropdown mobile-left">
                    <a
                        id="navbarDropdown"
                        class="nav-link dropdown-toggle"
                        href="#"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <font-awesome-icon v-if="isAuthenticated" :icon="['fad', 'user-check']" />
                        <font-awesome-icon v-if="!isAuthenticated" :icon="['fad', 'user']" />
                        <span v-if="isAuthenticated">&nbsp;{{ friendlyUsername }}</span>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                        <router-link v-if="isAuthenticated" :to="{ name: 'settings', params: { slug: userId } }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'id-badge']" />&nbsp;
                            <span>{{ $t('settings') }}</span>
                        </router-link>

                        <router-link v-if="isAuthenticated" :to="{ name: 'changepassword', params: { slug: userId } }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'key']" />&nbsp;
                            <span>{{ $t('password') }}</span>
                        </router-link>

                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-divider"></div>

                        <router-link v-if="isAuthenticated && hasRoleAdmin" :to="{ name: 'useradministration_list' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'user']" />&nbsp;
                            <span>{{ $t('useradmin') }}</span>
                        </router-link>

                        <router-link v-if="isAuthenticated && hasRoleAdmin" :to="{ name: 'admin_dashboard' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'tachometer-alt-fast']" />&nbsp;
                            <span>Dashboard</span>
                        </router-link>

                        <router-link v-if="isAuthenticated && hasRoleAdmin" :to="{ name: 'booktype_list' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'file-import']" />&nbsp;
                            <span>Prüfbuchtyp</span>
                        </router-link>

                        <div v-if="isAuthenticated" class="dropdown-divider"></div>

                        <a v-if="isAuthenticated" id="logout" href="" class="dropdown-item" @click="logout()">
                            <font-awesome-icon :icon="['fad', 'sign-out']" />&nbsp;
                            <span>{{ $t('logout') }}</span>
                        </a>

                        <router-link v-if="!isAuthenticated" :to="{ name: 'login' }" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'sign-in']" />&nbsp;
                            <span>{{ $t('login') }}</span>
                        </router-link>

                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-divider"></div>

                        <div v-if="isAuthenticated && hasRoleAdmin" class="dropdown-item">
                            <font-awesome-icon :icon="['fad', 'info-circle']" />
                            <span class="menu-text"> Version: {{ $store.getters.appVersion.substr(1, $store.getters.appVersion.length - 2) }} </span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import auth from '../auth/auth.helpers';
// import router from '@/common/router'
import { mapGetters } from 'vuex';

export default {
    name: 'Navbar',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        msg: { type: String },
    },
    computed: {
        ...mapGetters({
            hasRoleAdmin: 'auth/hasRoleAdmin',
            hasRoleAuswertung: 'auth/hasRoleAuswertung',
            hasRoleProject: 'auth/hasRoleProject',
            hasRoleProjectOnly: 'auth/hasRoleProjectOnly',
            hasRoleLager: 'auth/hasRoleLager',
            hasRoleFiliale: 'auth/hasRoleFiliale',
            hasRoleServiceTechniker: 'auth/hasRoleServiceTechniker',
            hasCustomerRoleDefault: 'auth/hasCustomerRoleDefault',
            hasCustomerRoleControl: 'auth/hasCustomerRoleControl',
            hasCustomerRoleRegionlManager: 'auth/hasCustomerRoleRegionlManager',
        }),
        friendlyUsername() {
            return this.$store.state.auth.friendlyUsername;
        },
        userId() {
            return this.$store.state.auth.userId;
        },
        // selectedMandant() {
        //     return this.$store.state.mandant.selectedMandant;
        // },
        mandanten() {
            return this.$store.state.mandant.mandanten;
        },
        isAuthenticated() {
            return this.$store.state.auth.isLoggedIn;
        },
        notHasRoleProjectOnlyOrFiliale() {
            return !this.hasRoleProjectOnly && !this.hasRoleFiliale;
        },
    },

    methods: {
        logout: function () {
            auth.logout();
        },

        // changeMandant: function (mandant) {
        //     // this.$store.dispatch("mandant/selectMandant",mandant).then( () => {
        //     //         router.push({name: 'home'});
        //     //     });
        // },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>

<!--language=json -->
<i18n>
    {
        "de": {
            "main": "Zugang",
            "settings": "Profil",
            "password": "Passwort",
            "sessions": "Sitzungen",
            "login": "Anmelden",
            "logout": "Abmelden",
            "useradmin": "Useradministration",
            "near": "Meine Objekte",
            "register": "Registrierung"
        },
        "en": {
            "main": "Account",
            "settings": "Profile",
            "password": "Password",
            "sessions": "Sessions",
            "login": "Sign in",
            "logout": "Sign out",
            "useradmin": "Useradministration",
            "near": "Lists near to you",
            "register": "Register"
        }
    }

</i18n>
