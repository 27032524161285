import config from '@/config/configuration';

const ConfigService = {
    getConfigIfExists(key) {
        if (config[window.location.hostname] && config[window.location.hostname][key]) {
            return config[window.location.hostname][key];
        }
        switch (key) {
            case 'backendBaseUrl':
                return '/';
            case 'I18N_LOCALE':
                return 'de';
            case 'I18N_FALLBACK_LOCALE':
                return 'en';
            case 'stage':
                return 'local';
            default:
                return '';
        }
    },
};

export default ConfigService;
