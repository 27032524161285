import Vue from 'vue';
import InspectionService from './inspection.service';
import * as _ from 'lodash';
import download from 'downloadjs';

const getDefaultBook = () => ({});
const getDefaultMisc = () => ({});

const getDefaultItem = () => ({
    when: new Date(),
    where: null,
    who: null,
    isAbnahmeGutachten: false,
    akkutausch: false,
    defects: [],
    _id: null,
    text: '',
    book: getDefaultBook(),
    misc: getDefaultMisc(),
});
const getDefaultSelfinspection = () => ({
    when: new Date(),
    where: null,
    who: null,
    defects: [],
    _id: null,
    text:
        'Die Eigenkontrollen gemäß Punkt 7.1 und 7.2.1 wurden durchgeführt.\n' +
        'Dazu zählen:\n' +
        '* Zugänglichkeit zu der Zentrale als auch zum Schaltschrank\n' +
        '* Notstromversorgung (zweimaliges Öffnen und Schließen der RAA im Ersatzstrombetrieb)\n' +
        '* Interne Signal- und Alarmierungseinrichtungen (FT4 Taster)\n' +
        '* Anlagen resultierende maximale Lagerhöhe oder Nutzungsbeschränkung',
    book: getDefaultBook(),
    misc: getDefaultMisc(),
});

const getDefaultState = () => ({
    item: getDefaultItem(),
    selfinspection: getDefaultSelfinspection(),
    list: [],
    currentPage: 1,
    totalPages: 1,
});

const inspection = {
    namespaced: true,

    state: Object.assign({}, getDefaultState()),

    mutations: {
        update(state, data) {
            Vue.set(state, 'item', data);
        },
        updateSelfInspection(state, data) {
            Vue.set(state, 'selfinspection', data);
        },
        updateList(state, data) {
            Vue.set(state, 'list', data);
        },
        updateCurrentPage(state, data) {
            Vue.set(state, 'currentPage', data);
        },
        updateTotalPages(state, data) {
            Vue.set(state, 'totalPages', data);
        },
        clear(state) {
            Vue.set(state, 'item', JSON.parse(JSON.stringify(getDefaultState().item)));
            Vue.set(state, 'list', JSON.parse(JSON.stringify(getDefaultState().list)));
            Vue.set(state, 'selfinspection', JSON.parse(JSON.stringify(getDefaultState().selfinspection)));
        },
    },

    actions: {
        async clear(context) {
            context.commit('clear');
        },
        async create(context, client_data) {
            const { data } = await InspectionService.create(client_data);
            context.commit('update', data);
            return data;
        },
        async createExistingDoc(context, client_data) {
            const { data } = await InspectionService.createExistingDoc(client_data);
            context.commit('update', data);
            return data;
        },
        async createselfinspection(context, client_data) {
            const { data } = await InspectionService.createselfinspection(client_data);
            context.commit('updateSelfInspection', data);
            return data;
        },
        async createAndGetPdf(context, client_data) {
            const x = await InspectionService.createAndGetPdf(client_data);

            const fileName = `Gutachten${client_data.book.manufacturerNo}.pdf`;
            download(new Blob([x.data]), fileName, 'application/pdf');

            if (x) return x;
        },
        async getPreInspectionPdf(context, client_data) {
            const { data } = await InspectionService.getPreInspectionPdf(client_data);

            const fileName = `Gutachten - ${client_data.book.object} - ${client_data.book.site}.pdf`;
            download(new Blob([data]), fileName, 'application/pdf');
        },
        async getGutachtenPdf(context, client_data) {
            debugger;
            const { data } = await InspectionService.getInspectionPdf(client_data.blobId);
            const fileName = client_data.fileName ? client_data.fileName : 'Prüfgutachten.pdf';

            download(new Blob([data]), fileName, 'application/pdf');
        },
        async update(context, client_data) {
            const { data } = await InspectionService.update(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async updateExistingDoc(context, client_data) {
            const { data } = await InspectionService.updateExistingDoc(client_data._id, client_data);
            context.commit('update', data);
            return data;
        },
        async updateselfinspection(context, client_data) {
            const { data } = await InspectionService.updateselfinspection(client_data._id, client_data);
            context.commit('updateSelfInspection', data);
            return data;
        },
        async updateAndGetPdf(context, client_data) {
            const { data } = await InspectionService.updateAndGetPdf(client_data._id, client_data);

            const fileName = `Gutachten${client_data.book.manufacturerNo}.pdf`;
            download(new Blob([data]), fileName, 'application/pdf');
        },
        async get(context, id) {
            const { data } = await InspectionService.get(id);
            context.commit('update', data);
            return data;
        },
        async getselfinspection(context, id) {
            const { data } = await InspectionService.getselfinspection(id);
            context.commit('updateSelfInspection', data);
            return data;
        },
        async queryAbnStats(context, client_data) {
            let params = `abnStats/${client_data.tenantId}/${client_data.from}/${client_data.to}`;

            const { data } = await InspectionService.get(params);
            context.commit('updateList', data);
            return data;
        },
        async query(context, params) {
            const { data } = await InspectionService.query(params);

            let currPage = params && params.params && params.params.page ? params.params.page : 0;
            context.commit('updateCurrentPage', currPage);

            let totalPages = data.pages;

            context.commit('updateTotalPages', _.ceil(totalPages));

            context.commit('updateList', data);
            return data;
        },
        async delete(context, slug) {
            const { data } = await InspectionService.delete(slug);
            context.commit('updateList', data);
            return data;
        },
    },

    getters: {
        data(state) {
            return state.item;
        },
        selfinspection(state) {
            return state.selfinspection;
        },
        list(state) {
            return state.list;
        },
        currentPage(state) {
            return state.currentPage;
        },
        totalPages(state) {
            return state.totalPages;
        },
    },
};

export default inspection;
