import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'tenants';

const TenantService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getByUrl(slug) {
        return ApiService.get(resource + '/url', slug);
    },
    async getByUrlAndPath(url, folder, currentPage, sort, size) {
        if (folder == null) {
            return ApiService.getCustom(`${resource}/url/${url}/root/${currentPage}/${sort}/${size}`);
        } else {
            return ApiService.getCustom(`${resource}/url/${url}/${folder}/${currentPage}/${sort}/${size}`);
        }
    },
    async getTechAcceptor() {
        return ApiService.get(resource + '/techacceptor');
    },
    async getByUserId(slug) {
        return ApiService.get(resource + '/user', slug);
    },
    async getByBookId(bookId) {
        return ApiService.get(resource + '/book', bookId);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async template() {
        return ApiService.get('/template');
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params, showToast = true) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            if (showToast) {
                Toast.showSaveOkToastI18n();
            }
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default TenantService;
