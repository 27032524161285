import ApiService from '@/common/api.service';

const AdminService = {
    // async get(slug) {
    //     return ApiService.get(resource, slug);
    // },
    // async query(params) {
    //     return ApiService.query(resource, params);
    // },
    async getAllDailyInspections(dateYYYYMMDD) {
        const path = `allDailyInspectionUser/${dateYYYYMMDD}`;
        const resource = `lists`;
        return ApiService.get(resource, path);
    },
};

export default AdminService;
