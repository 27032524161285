import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'inspections';

const InspectionService = {
    async get(slug) {
        return ApiService.get(resource, slug);
    },
    async getselfinspection(slug) {
        return ApiService.get('self' + resource, slug);
    },
    async query(params) {
        return ApiService.query(resource, params);
    },
    async create(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async createExistingDoc(params) {
        let promise = null;
        try {
            promise = await ApiService.post(resource + '/existingDoc', params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async createselfinspection(params) {
        let promise = null;
        try {
            promise = await ApiService.post('selfinspections', params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateExistingDoc(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource + '/existingDoc', slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateselfinspection(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update('selfinspections', slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async createAndGetPdf(params) {
        let promise = null;
        try {
            promise = await ApiService.postAndResultPdf(resource, params);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async getPreInspectionPdf(slug) {
        let promise = null;
        try {
            promise = await ApiService.getPdf(`${resource}/preInspectionPdf/`, slug);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async getInspectionPdf(slug) {
        let promise = null;
        try {
            promise = await ApiService.getPdf(`${resource}/inspectionPdf`, slug);
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async updateAndGetPdf(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.updateAndResultPdf(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async delete(slug) {
        let promise = null;
        try {
            promise = await ApiService.delete(resource, slug);
            Toast.showDeleteOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default InspectionService;
