<template>
    <div class="red-text">
        <div v-if="validation.required != null && !validation.required">
            {{ $t('entity.validation.required') }}
        </div>
        <div v-if="!validation.minLength && validation.$params.minLength">
            {{ $t('entity.validation.minLength', { value: validation.$params.minLength.min }) }}
        </div>
        <div v-if="!validation.maxLength && validation.$params.maxLength">
            {{ $t('entity.validation.maxLength', { value: validation.$params.maxLength.max }) }}
        </div>
        <div v-if="!validation.minValue && validation.$params.minValue">
            {{ $t('entity.validation.minValue', { value: validation.$params.minValue.min }) }}
        </div>
        <div v-if="!validation.maxValue && validation.$params.maxValue">
            {{ $t('entity.validation.maxValue', { value: validation.$params.maxValue.max }) }}
        </div>
        <div v-if="!validation.between && validation.$params.between">
            {{
                $t('entity.validation.maxValue', {
                    min: validation.$params.between.min,
                    max: validation.$params.between.max,
                })
            }}
        </div>
        <div v-if="validation.integer != null && !validation.integer">{{ $t('entity.validation.integer') }}</div>
        <div v-if="validation.numeric != null && !validation.numeric">{{ $t('entity.validation.numeric') }}</div>
        <div v-if="validation.decimal != null && !validation.decimal">{{ $t('entity.validation.decimal') }}</div>
        <div v-if="validation.email != null && !validation.email">{{ $t('entity.validation.email') }}</div>
        <div v-if="validation.url != null && !validation.url">{{ $t('entity.validation.url') }}</div>
    </div>
</template>

<script>
export default {
    name: 'Validation',
    props: {
        // vue/require-default-prop vue/require-prop-types
        // eslint-disable-next-line
        validation: {},
    },
};
</script>

<style scoped></style>
