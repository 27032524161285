<template>
    <div v-if="visible">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog modal-dialog-scrollable" :class="size" role="document">
                        <div class="modal-content">
                            <slot name="header"></slot>
                            <slot name="body"></slot>
                            <slot name="footer"></slot>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'ModalDialog',
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
            default: null,
        },
    },
};
</script>
