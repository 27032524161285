import ApiService from '@/common/api.service';
import Toast from '@/common/toast';

const resource = 'folder';

const FolderService = {
    async queryById(folderId) {
        return ApiService.getCustom(`${resource}/id/${folderId}`);
    },
    async queryRoot(tenant) {
        return ApiService.getCustom(`${resource}/tenant/${tenant}`);
    },
    async queryByParentFolder(tenant, parentfolder) {
        return ApiService.getCustom(`${resource}/tenant/${tenant}/${parentfolder}`);
    },
    async create(tenant, parentfolder, folderName) {
        let promise = null;
        try {
            let path = `${resource}/tenant/${tenant}`;
            if (parentfolder != null) {
                path += `/${parentfolder}`;
            }
            promise = await ApiService.post(path, { folderName });
            Toast.showCreateOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
    async update(slug, params) {
        let promise = null;
        try {
            promise = await ApiService.update(resource, slug, params);
            Toast.showSaveOkToastI18n();
        } catch (e) {
            Toast.showSaveErrorToastI18n();
        }
        return promise;
    },
};

export default FolderService;
